import React from 'react';
import './App.css';
import {BrowserRouter} from 'react-router-dom';
import {Route} from 'react-router';
import Authenticator from './components/Authenticator';


function deleteDeprecatedCookies() {
  let client = process.env.REACT_APP_DEPRECATED_CLIENT;
  let domain = process.env.REACT_APP_DEPRECATED_DOMAIN;
  if (!client || !domain) {
    return;
  }
  let cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    if (name.includes(client)) {
      document.cookie = name + "=; path=/; domain="+domain+";expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
}

const App = () => {
  deleteDeprecatedCookies();
  return (
    <BrowserRouter>
      <Route path="/">
        <Authenticator/>
      </Route>
    </BrowserRouter>
  );
}

export default App;
