import {I18n} from 'aws-amplify';
import {Translations} from '@aws-amplify/ui-components';

I18n.putVocabulariesForLanguage('es', {
  [Translations.SIGN_IN_HEADER_TEXT]: 'Iniciar Sesión',
  [Translations.SIGN_IN_ACTION]: 'Iniciar Sesión',
  [Translations.BACK_TO_SIGN_IN]: 'Iniciar Sesión',
  [Translations.SIGN_UP_HEADER_TEXT]: 'Crear Cuenta',
  [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: '¿Tiene una cuenta?',
  [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Registrarse',
  [Translations.SIGN_IN_TEXT]: 'Acceder',
  [Translations.NO_ACCOUNT_TEXT]: '¿No posee un cuenta?',
  [Translations.FORGOT_PASSWORD_TEXT]: '¿Olvidó su contraseña?',
  [Translations.RESET_PASSWORD_TEXT]: 'Recuperar contraseña',
  [Translations.CREATE_ACCOUNT_TEXT]: 'Registrarse',
  [Translations.USERNAME_PLACEHOLDER]: 'Usuario',
  [Translations.USERNAME_LABEL]: 'Usuario',
  [Translations.PASSWORD_LABEL]: 'Contraseña',
  [Translations.PASSWORD_PLACEHOLDER]: 'Contraseña',
  [Translations.NAME_LABEL]: 'Nombre',
  [Translations.NAME_PLACEHOLDER]: 'Nombre',
  [Translations.EMAIL_LABEL]: 'Correo Electrónico',
  [Translations.EMAIL_PLACEHOLDER]: 'Correo Electrónico',
  [Translations.PHONE_LABEL]: 'Teléfono',
  [Translations.PHONE_PLACEHOLDER]: 'Teléfono',
  [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: 'Confirmar Registro',
  [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Confirmar',
  [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: 'Código de Confirmación',
  [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: 'Código de Confirmación',
  [Translations.CONFIRM_SIGN_UP_LOST_CODE]: '¿No obtuvo el código?',
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: 'Reenviar',
  [Translations.CONFIRM_SIGN_UP_FAILED]: 'Confirmación fallida',
  [Translations.CONFIRM_SMS_CODE]: 'Confirmación vía SMS',
  [Translations.CONFIRM_TOTP_CODE]: 'Confirmación vía OTP',
  [Translations.CODE_LABEL]: 'Código de Verificación',
  [Translations.CODE_PLACEHOLDER]: 'Código de Verificación',
  [Translations.SIGN_OUT]: 'Salir',
  [Translations.SELECT_MFA_TYPE_HEADER_TEXT]: 'Configuración MFA',
  [Translations.SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT]: 'Configurar',
  [Translations.UNABLE_TO_SETUP_MFA_AT_THIS_TIME]: 'No se puede modificar la configuración MFA en este momento.',
  [Translations.RESET_YOUR_PASSWORD]: 'Recuperar contraseña',
  [Translations.SEND_CODE]: 'Enviar código',
  'Incorrect username or password.': 'Usuario o contraseña incorrecta.',
  [Translations.NEW_PASSWORD_LABEL]: 'Nueva contraseña',
  [Translations.NEW_PASSWORD_PLACEHOLDER]: 'Nueva contraseña',
  [Translations.SUBMIT]: 'Enviar',
  'Invalid code or auth state for the user.': 'Código de confirmación inválido',
  'User is not confirmed.': 'Usuario no confirmado'
});
