import React from 'react';
import './Authenticator.css';
import {
  AmplifyAuthenticator,
  AmplifySignOut,
  AmplifySignUp,
} from '@aws-amplify/ui-react';
import {useLocation} from 'react-router-dom';
import {AuthState, onAuthUIStateChange} from '@aws-amplify/ui-components';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Authenticator = () => {
  let query = useQuery();
  let signUpFields = [
    {type: 'name', label: 'Nombre', placeholder: 'Nombre', required: true},
    {type: 'email', required: true},
    {type: 'username', required: true},
    {type: 'password', required: true},
    {type: 'phone_number', required: true},
  ]

  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  if (authState === AuthState.SignedIn && user) {
    let url = query.get('redirectTo');
    if (url === undefined || !url || url.trim() === '') {
      url = process.env.REACT_APP_DEFAULT_HOME
    }
    window.location = url
    return null;
  } else {
    return (
      <div className="container">
        <AmplifyAuthenticator>
          <AmplifySignUp formFields={signUpFields} slot="sign-up"/>
        </AmplifyAuthenticator>
      </div>
    );
  }
}

export default Authenticator;
