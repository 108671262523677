const {Amplify} = require('aws-amplify');

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_WEB_CLIENT_ID,
    mandatorySignIn: true,
    cookieStorage: {
      domain: process.env.REACT_APP_AWS_COOKIE_DOMAIN,
      path: '/',
      expires: 7,
      sameSite: 'strict',
      secure: true
    },
  }
});
